import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import justRing from "../../../Assets/LandingPage/Clients/justRing.png";
import partner_1White from "../../../Assets/LandingPage/Clients/iecWhite.png";
import rrTech from "../../../Assets/LandingPage/Clients/rrtech.png";
import partner_4 from "../../../Assets/LandingPage/Clients/partner_2.png";
import gryphalCode from "../../../Assets/LandingPage/Clients/gryphalCode.png";
import phoenixTech from "../../../Assets/LandingPage/Clients/phoenixTechSol.png";
import partner_5 from "../../../Assets/LandingPage/Clients/Zlan.png";
import Fortune from "../../../Assets/LandingPage/Clients/fortuneContainers.png";
import cbeAToZ from "../../../Assets/LandingPage/Clients/cbeAToZLogo.png";
import KYP from "../../../Assets/LandingPage/Clients/KYP.png";

function Clients() {
	return (
		<section id='clients'>
			<Box sx={{ backgroundColor: "#0b0b0b", overflow: "hidden" }}>
				<Typography
					sx={{
						fontSize: { sm: "120px", xs: "80px" },
						lineHeight: "1.9em",
						fontWeight: 400,
						color: "transparent",
						WebkitTextStroke: "2px #d4ba85",
						textStroke: "3px #d4ba85",
						whiteSpace: "nowrap",
						animation: "marquee 25s linear infinite",
						"@keyframes marquee": {
							"0%": { transform: "translateX(100%)" },
							"100%": { transform: "translateX(-100%)" },
						},
					}}
				>
					Our reputed world wide partners Our reputed world wide partners Our
					reputed world wide partners Our reputed world wide partners Our
					reputed world wide partners Our reputed world wide partners Our
					reputed world wide partners Our reputed world wide partners Our
					reputed world wide partners
				</Typography>
			</Box>
			<Box
				sx={{
					backgroundColor: "#0b0b0b",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					"@media (min-width: 1441px)": {
						minHeight: "auto",
					},
					"@media (max-width: 830px)": {
						minHeight: "auto",
					},
				}}
			>
				<Box sx={{ maxWidth: "1440px", width: "100%", height: "100%" }}>
					<Box
						sx={{
							marginLeft: "5%",
							marginRight: "5%",
							height: "100%",
							marginTop: "7%",
							marginBottom: "6%",
						}}
					>
						<Grid
							container
							spacing={{ sm: 10, xs: 1 }}
							sx={{
								display: "flex",
								justifyContent: { xs: "space-between", sm: "center" },
								alignItems: "center",
							}}
						>
							<Grid item xs={2}>
								<img src={KYP} style={{ width: "100%" }} />	
							</Grid>
							<Grid item xs={2}>
								<img src={justRing} style={{ width: "100%" }} />
							</Grid>
							<Grid item xs={2}>
								<img src={phoenixTech} style={{ width: "100%" }} />
							</Grid>
							<Grid item xs={2}>
								<img src={rrTech} style={{ width: "100%" }} />
							</Grid>
							<Grid item xs={2}>
								<img src={Fortune} style={{ width: "100%" }} />
							</Grid>
						</Grid>
					</Box>
					<Box
					>
						<Box>
							<Typography
								sx={{
									color: "white",
									fontSize: { sm: "30px", xs: "15px" },
									fontFamily: "Poppins, sans-serif",
									fontWeight: 600,
									lineHeight: 1.25,
									textAlign: "center",
								}}
							>
								OUR PARTNERSHIPS
							</Typography>
						</Box>						
					</Box>
					<Box
						sx={{
							marginTop: "2%",
							marginBottom: "6%",
						}}
					>
						<Grid
							container
							spacing={{ sm: 10, xs: 1 }}
							sx={{
								display: "flex",
								justifyContent: { xs: "space-between", sm: "center" },
								alignItems: "center",
							}}
						>
							<Grid item xs={2}>
								<img src={partner_1White} style={{ width: "100%" }} />
							</Grid>
							<Grid item xs={2}>
								<img src={cbeAToZ} style={{ width: "100%" }} />
							</Grid>
							<Grid item xs={2}>
								<img src={gryphalCode} style={{ width: "100%" }} />
							</Grid>
						</Grid>
					</Box>
				</Box>
			</Box>
		</section>
	);
}

export default Clients;
