import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import service_3 from "../../../Assets/LandingPage/Services/service_3.jpeg";
import service_4 from "../../../Assets/LandingPage/Services/service_4.jpeg";
import service_1 from "../../../Assets/LandingPage/Services/service_1.jpeg";
import service_2 from "../../../Assets/LandingPage/Services/service_2.jpeg";

function Services() {
	return (
		<section id='services'>
			<Box
				sx={{
					backgroundColor: "hsl(0deg 0% 9.02%)",
					minHeight: "100vh",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					"@media (min-width: 1441px)": {
						minHeight: "auto",
					},
					"@media (max-width: 830px)": {
						minHeight: "auto",
					},
				}}
			>
				<Box sx={{ maxWidth: "1440px", width: "100%", height: "100%" }}>
					<Box
						sx={{
							marginLeft: "5%",
							marginRight: "5%",
							height: "100%",
							marginTop: "10%",
							marginBottom: "10%",
						}}
					>
						<Grid container>
							<Grid item xs={12} sm={4}>
								<Box>
									<Typography
										sx={{
											color: "hsla(0,0%,100%,.7)",
											marginBottom: "5%",
											fontSize: "16px",
											fontFamily: "Poppins, sans-serif",
											fontWeight: 600,
											lineHeight: "1.75em",
											marginTop: "3%",
											textAlign: "justify",
										}}
									>
										What Can We Do
									</Typography>
									<Typography
										sx={{
											color: "white",
											fontSize: "50px",
											fontFamily: "Poppins, sans-serif",
											fontWeight: 600,
											lineHeight: 1.25,
										}}
									>
										Services we can help you with
									</Typography>
								</Box>
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										marginTop: { sm: "15%", xs: "5%" },
									}}
								>
									<Typography
										sx={{ color: "white", fontWeight: 600, marginRight: "8px" }}
									>
										See All Services
									</Typography>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										xmlnsXlink='http://www.w3.org/1999/xlink'
										aria-hidden='true'
										role='img'
										className='iconify iconify--bi'
										width='1em'
										height='1em'
										viewBox='0 0 16 16'
									>
										<path
											fill='white'
											fillRule='evenodd'
											d='M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8'
										></path>
									</svg>
								</Box>
							</Grid>
							<Grid
								item
								xs={12}
								sm={8}
								sx={{
									padding: "15px",
									display: "flex",
									flexDirection: "column",
								}}
							>
								<Box>
									<Grid container spacing={2} className='service'>
										<Grid item xs={0} sm={3}></Grid>
										<Grid item xs={12} sm={3}>
											<img
												src={service_1}
												style={{ width: "100%", borderRadius: "10px" }}
												alt='Service 1'
											></img>
										</Grid>
										<Grid item xs={0} sm={3}></Grid>
										<Grid item xs={12} sm={3}>
											<img
												src={service_2}
												style={{ width: "100%", borderRadius: "10px" }}
												alt='Service 2'
											></img>
										</Grid>
									</Grid>
								</Box>
								<Box>
									<Grid container spacing={2} className='service'>
										<Grid item xs={12} sm={3}>
											<img
												src={service_3}
												style={{ width: "100%", borderRadius: "10px" }}
												alt='Service 1'
											></img>
										</Grid>
										<Grid item xs={0} sm={3}></Grid>
										<Grid item xs={12} sm={3}>
											<img
												src={service_4}
												style={{ width: "100%", borderRadius: "10px" }}
												alt='Service 2'
											></img>
										</Grid>
										<Grid item xs={0} sm={3}></Grid>
									</Grid>
								</Box>
							</Grid>
						</Grid>
					</Box>
				</Box>
			</Box>
		</section>
	);
}

export default Services;
