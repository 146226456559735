import React from "react";
import Logo from "../../Assets/LandingPage/navbar/logo.png";
import Hamburger from "./hamburger";

export const Navbar = () => {
	function toggleFullScreen(elem) {
		if (
			(document.fullScreenElement !== undefined &&
				document.fullScreenElement === null) ||
			(document.msFullscreenElement !== undefined &&
				document.msFullscreenElement === null) ||
			(document.mozFullScreen !== undefined && !document.mozFullScreen) ||
			(document.webkitIsFullScreen !== undefined &&
				!document.webkitIsFullScreen)
		) {
			if (elem.requestFullScreen) {
				elem.requestFullScreen();
			} else if (elem.mozRequestFullScreen) {
				elem.mozRequestFullScreen();
			} else if (elem.webkitRequestFullScreen) {
				elem.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
			} else if (elem.msRequestFullscreen) {
				elem.msRequestFullscreen();
			}
		} else {
			if (document.cancelFullScreen) {
				document.cancelFullScreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.webkitCancelFullScreen) {
				document.webkitCancelFullScreen();
			} else if (document.msExitFullscreen) {
				document.msExitFullscreen();
			}
		}
	}
	return (
		<nav>
			<div className='navbar'>
				<img src={Logo} height={"50px"} />
				<ul className='menu' onClick={toggleFullScreen(document.body)}>
					<a href='#home'>
						<li>Home</li>
					</a>
					<a href='#funfact'>
						<li>About Us</li>
					</a>
					<a href='#services'>
						<li>Services</li>
					</a>
					<a href='#testimonial'>
						<li>Testimonials</li>
					</a>
					<a href='#contact'>
						<li>Contact</li>
					</a>
				</ul>
				{/* <Hamburger /> */}
			</div>
		</nav>
	);
};
