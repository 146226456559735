import { Box, Grid, Typography } from "@mui/material";
import React from "react";

function Awards() {
	return (
		<section id='awards'>
			<Box
				sx={{
					backgroundColor: "#0b0b0b",
					minHeight: "100vh",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					"@media (min-width: 1441px)": {
						minHeight: "auto",
					},
					"@media (max-width: 830px)": {
						minHeight: "auto",
					},
				}}
			>
				<Box sx={{ maxWidth: "1440px", width: "100%", height: "100%" }}>
					<Box
						sx={{
							marginLeft: "5%",
							marginRight: "5%",
							height: "100%",
							marginTop: "10%",
						}}
					>
						<Grid container sx={{ height: "auto" }}>
							<Grid item sm={4} xs={12}>
								<Box>
									<Typography
										sx={{
											color: "hsla(0,0%,100%,.7)",
											marginBottom: "5%",
											fontSize: "16px",
											fontFamily: "Poppins, sans-serif",
											fontWeight: 600,
											lineHeight: "1.75em",
											marginTop: "3%",
											textAlign: "justify",
										}}
									>
										Our recognitions
									</Typography>
									<Typography
										sx={{
											color: "white",
											fontSize: { sm: "38px", xs: "28px" },
											width: { sm: "100%", xs: "75%" },
											fontFamily: "Poppins, sans-serif",
											fontWeight: 600,
											lineHeight: 1.25,
										}}
									>
										How we ingrained in the essence of IT?
									</Typography>
								</Box>
							</Grid>
							<Grid
								item
								sm={4}
								xs={12}
								sx={{ display: "flex", flexDirection: "column" }}
							>
								<Box
									sx={{
										marginLeft: { sm: "15%", xs: "0%" },
										marginTop: { sm: "0%", xs: "5%" },
									}}
								>
									<Typography
										style={{
											color: "#d4ba85",
											fontSize: "30px",
											fontFamily: "Poppins, sans-serif",
											fontWeight: 600,
											lineHeight: 1.25,
										}}
									>
										2021
									</Typography>
									<Typography
										style={{
											color: "white",
											fontSize: "30px",
											fontFamily: "Poppins, sans-serif",
											fontWeight: 600,
											lineHeight: 1.25,
										}}
									>
										awwwards
									</Typography>
									<Typography
										sx={{
											color: "hsla(0,0%,100%,.7)",
											marginBottom: "5%",
											fontSize: "16px",
											fontFamily: "Poppins, sans-serif",
											fontWeight: 500,
											lineHeight: "1.75em",
											marginTop: "3%",
											textAlign: "justify",
										}}
									>
										Nominiee of <br />
										awwwards.com
									</Typography>
								</Box>

								<Box
									sx={{ marginLeft: { sm: "15%", xs: "0%" }, marginTop: "3%" }}
								>
									<Typography
										style={{
											color: "#d4ba85",
											fontSize: "30px",
											fontFamily: "Poppins, sans-serif",
											fontWeight: 600,
											lineHeight: 1.25,
										}}
									>
										2023
									</Typography>
									<Typography
										style={{
											color: "white",
											fontSize: "30px",
											fontFamily: "Poppins, sans-serif",
											fontWeight: 600,
											lineHeight: 1.25,
										}}
									>
										Leet Code
									</Typography>
									<Typography
										sx={{
											color: "hsla(0,0%,100%,.7)",
											marginBottom: "5%",
											fontSize: "16px",
											fontFamily: "Poppins, sans-serif",
											fontWeight: 500,
											lineHeight: "1.75em",
											marginTop: "3%",
											textAlign: "justify",
										}}
									>
										Solution of the day <br />
										Optimized Programming
									</Typography>
								</Box>
							</Grid>
							<Grid
								item
								xs={12}
								sm={4}
								sx={{ display: "flex", flexDirection: "column" }}
							>
								<Box
									sx={{
										marginLeft: { sm: "15%", xs: "0%" },
										marginTop: { sm: "0%", xs: "5%" },
									}}
								>
									<Typography
										style={{
											color: "#d4ba85",
											fontSize: "30px",
											fontFamily: "Poppins, sans-serif",
											fontWeight: 600,
											lineHeight: 1.25,
										}}
									>
										2022
									</Typography>
									<Typography
										style={{
											color: "white",
											fontSize: "30px",
											fontFamily: "Poppins, sans-serif",
											fontWeight: 600,
											lineHeight: 1.25,
										}}
									>
										Transferwise
									</Typography>
									<Typography
										sx={{
											color: "hsla(0,0%,100%,.7)",
											marginBottom: "5%",
											fontSize: "16px",
											fontFamily: "Poppins, sans-serif",
											fontWeight: 500,
											lineHeight: "1.75em",
											marginTop: "3%",
											textAlign: "justify",
										}}
									>
										Hall of fame <br></br>
										Bugcrowd
									</Typography>
								</Box>

								<Box
									sx={{ marginLeft: { sm: "15%", xs: "0%" }, marginTop: "3%" }}
								>
									<Typography
										style={{
											color: "#d4ba85",
											fontSize: "30px",
											fontFamily: "Poppins, sans-serif",
											fontWeight: 600,
											lineHeight: 1.25,
										}}
									>
										2023
									</Typography>
									<Typography
										style={{
											color: "white",
											fontSize: "30px",
											fontFamily: "Poppins, sans-serif",
											fontWeight: 600,
											lineHeight: 1.25,
										}}
									>
										Vimeo
									</Typography>
									<Typography
										sx={{
											color: "hsla(0,0%,100%,.7)",
											marginBottom: "5%",
											fontSize: "16px",
											fontFamily: "Poppins, sans-serif",
											fontWeight: 500,
											lineHeight: "1.75em",
											marginTop: "3%",
											textAlign: "justify",
										}}
									>
										Happily Secured <br />
										Magisto
									</Typography>
								</Box>
							</Grid>
						</Grid>
					</Box>
					<Box
						sx={{
							marginLeft: "5%",
							marginRight: "5%",
							marginTop: "4%",
							marginBottom: "10%",
						}}
					>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<Typography
								sx={{
									textAlign: "center",
									color: "white",
									fontSize: { sm: "50px", xs: "30px" },
									fontFamily: "Poppins, sans-serif",
									fontWeight: 600,
									lineHeight: 1.25,
								}}
							>
								Our agile process is ability to adapt and respond to change.
								Agile organizations view change as an opportunity, not a threat.
							</Typography>
						</Box>
					</Box>
				</Box>
			</Box>
		</section>
	);
}

export default Awards;
