import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import HeroImage from "../../../Assets/LandingPage/HomePage/hero_bg.jpeg";

function HomePage() {
	const parallaxRef = useRef(null);

	useEffect(() => {
		const handleScroll = () => {
			const scrollValue = window.scrollY;
			parallaxRef.current.style.backgroundPositionY = `${scrollValue * 0.5}px`;
		};

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<section id='home'>
			<Box
				ref={parallaxRef}
				sx={{
					backgroundImage: `url('${HeroImage}')`,
					backgroundRepeat: "no-repeat",
					backgroundPosition: "center",
					backgroundSize: "cover",
					minHeight: "120vh",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Box sx={{ maxWidth: "1440px", width: "100%" }}>
					<Box
						sx={{
							marginLeft: "5%",
							marginTop: { xs: "0%", sm: "5%" },
							marginRight: "5%",
						}}
					>
						<Box>
							<Typography
								variant='h1'
								sx={{
									color: "white",
									fontSize: {
										xs: "42px",
										sm: "80px",
										md: "56px",
										lg: "80px",
										xl: "120px",
									},
									fontFamily: "Poppins, sans-serif",
									fontWeight: 700,
									lineHeight: 1.25,
								}}
							>
								Your Digital <br /> Odyssey Begins
							</Typography>
						</Box>
						<Box
							sx={{
								marginTop: "7%",
							}}
						>
							<Grid container direction={{ xs: "column-reverse", sm: "row" }}>
								<Grid item xs={12} sm={6}>
									<Box sx={{ display: "flex", alignItems: "center" }}>
										<Typography
											sx={{
												color: "white",
												fontWeight: 600,
												marginRight: "8px",
											}}
										>
											Get a Quote
										</Typography>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											xmlnsXlink='http://www.w3.org/1999/xlink'
											aria-hidden='true'
											role='img'
											className='iconify iconify--bi'
											width='1em'
											height='1em'
											viewBox='0 0 16 16'
										>
											<path
												fill='white'
												fillRule='evenodd'
												d='M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8'
											></path>
										</svg>
									</Box>
								</Grid>
								<Grid item xs={12} sm={5}>
									<Box>
										<Typography
											sx={{
												color: "white",
												fontSize: "18px",
												lineHeight: "1.9em",
											}}
										>
											We're the IT Robin Hoods, stealing technical headaches and
											giving back digital peace of mind
										</Typography>
									</Box>
								</Grid>
							</Grid>
						</Box>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								marginTop: "2%",
								marginBottom: "2%",
							}}
						>
							<Box
								sx={{
									border: "2px solid white",
									borderRadius: "30px",
									paddingRight: "6px",
									paddingLeft: "6px",
								}}
							>
								<Typography
									sx={{ color: "white", fontSize: "20px", lineHeight: "1.9em" }}
								>
									.
								</Typography>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</section>
	);
}

export default HomePage;
