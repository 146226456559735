import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import Funfact_Shape_Bg from "../../../Assets/LandingPage/FunFact/funfact_shape_bg.svg";

function Funfact() {
	return (
		<section id='funfact'>
			<Box
				sx={{
					backgroundImage: `url('${Funfact_Shape_Bg}')`,
					backgroundRepeat: "no-repeat",
					backgroundPosition: "center",
					backgroundSize: "cover",
					backgroundColor: "#090909",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					"@media (min-width: 1441px)": {
						minHeight: "auto",
					},
					"@media (max-width: 830px)": {
						minHeight: "auto",
					},
				}}
			>
				<Box sx={{ maxWidth: "1440px", width: "100%" }}>
					<Box
						sx={{
							marginLeft: "5%",
							marginTop: "4%",
							marginRight: "10%",
							marginBottom: "4%",
						}}
					>
						<Grid container>
							<Grid item xs={12} sm={6}>
								<Box
									sx={{
										marginLeft: { sm: "15%", xs: "5%" },
										marginRight: { sm: "15%", xs: "0%" },
									}}
								>
									<Typography
										sx={{
											color: "white",
											fontSize: "50px",
											fontFamily: "Poppins, sans-serif",
											fontWeight: 600,
											lineHeight: 1.25,
										}}
									>
										Our fun fact
									</Typography>
									<Typography
										sx={{
											color: "hsla(0,0%,100%,.7)",
											fontSize: "16px",
											width: { sm: "100%", xs: "75%" },
											fontFamily: "Poppins, sans-serif",
											fontWeight: 500,
											lineHeight: "1.75em",
											marginTop: "3%",
											textAlign: "justify",
										}}
									>
										EL Codamics is more than just code - We're a passionate team
										that loves working with clients to bring their ideas to
										life. Every Friday, we have a "Pizza & Pitch" session where
										employees can share their innovative ideas for new projects
										or solutions.
										<br />
									</Typography>
								</Box>
							</Grid>
							<Grid
								item
								xs={12}
								sm={3}
								sx={{
									display: "flex",
									justifyContent: { sm: "center" },
									marginLeft: { xs: "5%", sm: "0%" },
									marginTop: { xs: "5%", sm: "0%" },
									alignItems: "center",
								}}
							>
								<Box>
									<Grid container>
										<Grid item xs={3}>
											<Typography
												sx={{
													color: "white",
													fontSize: "50px",
													fontFamily: "Poppins, sans-serif",
													fontWeight: 700,
													lineHeight: 1.25,
												}}
											>
												25
											</Typography>
										</Grid>
										<Grid item xs={9}>
											<Typography
												style={{
													marginLeft: "5px",
													color: "#d4ba85",
													fontSize: "23px",
													fontFamily: "Poppins, sans-serif",
													fontWeight: 600,
													lineHeight: 1.25,
												}}
											>
												+
											</Typography>
											<Typography
												style={{
													marginLeft: "10px",
													color: "grey",
													fontSize: "15px",
													fontFamily: "Poppins, sans-serif",
													fontWeight: 500,
													lineHeight: 1.25,
												}}
											>
												Global Happy Clients
											</Typography>
										</Grid>
									</Grid>

									<Box sx={{ marginTop: "5%" }}>
										<Grid container>
											<Grid item xs={4}>
												<Typography
													sx={{
														color: "white",
														fontSize: "50px",
														fontFamily: "Poppins, sans-serif",
														fontWeight: 700,
														lineHeight: 1.25,
													}}
												>
													120
												</Typography>
											</Grid>
											<Grid item xs={8}>
												<Typography
													style={{
														marginLeft: "10px",
														color: "#d4ba85",
														fontSize: "23px",
														fontFamily: "Poppins, sans-serif",
														fontWeight: 600,
														lineHeight: 1.25,
													}}
												>
													%
												</Typography>
												<Typography
													style={{
														marginLeft: "10px",
														color: "grey",
														fontSize: "15px",
														fontFamily: "Poppins, sans-serif",
														fontWeight: 500,
														lineHeight: 1.25,
													}}
												>
													Growth Rate
												</Typography>
											</Grid>
										</Grid>
									</Box>
								</Box>
							</Grid>
							<Grid
								item
								xs={12}
								sm={3}
								sx={{
									display: "flex",
									justifyContent: { sm: "center" },
									marginLeft: { xs: "5%", sm: "0%" },
									alignItems: "center",
								}}
							>
								<Box>
									<Grid container>
										<Grid item xs={2}>
											<Typography
												sx={{
													color: "white",
													fontSize: "50px",
													fontFamily: "Poppins, sans-serif",
													fontWeight: 700,
													lineHeight: 1.25,
												}}
											>
												10
											</Typography>
										</Grid>
										<Grid item xs={10}>
											<Typography
												style={{
													marginLeft: "10px",
													color: "#d4ba85",
													fontSize: "23px",
													fontFamily: "Poppins, sans-serif",
													fontWeight: 600,
													lineHeight: 1.25,
												}}
											>
												+
											</Typography>
											<Typography
												style={{
													marginLeft: "15px",
													color: "grey",
													fontSize: "15px",
													fontFamily: "Poppins, sans-serif",
													fontWeight: 500,
													lineHeight: 1.25,
												}}
											>
												Industry Contributions
											</Typography>
										</Grid>
									</Grid>

									<Box sx={{ marginTop: "5%" }}>
										<Grid container>
											<Grid item xs={3}>
												<Typography
													sx={{
														color: "white",
														fontSize: "50px",
														fontFamily: "Poppins, sans-serif",
														fontWeight: 700,
														lineHeight: 1.25,
													}}
												>
													07
												</Typography>
											</Grid>
											<Grid item xs={8}>
												<Typography
													style={{
														marginLeft: "10px",
														color: "#d4ba85",
														fontSize: "23px",
														fontFamily: "Poppins, sans-serif",
														fontWeight: 600,
														lineHeight: 1.25,
													}}
												>
													+
												</Typography>
												<Typography
													style={{
														marginLeft: "10px",
														color: "grey",
														fontSize: "15px",
														fontFamily: "Poppins, sans-serif",
														fontWeight: 500,
														lineHeight: 1.25,
													}}
												>
													Own Products
												</Typography>
											</Grid>
										</Grid>
									</Box>
								</Box>
							</Grid>
						</Grid>
					</Box>
				</Box>
			</Box>
		</section>
	);
}

export default Funfact;
