import { Box, Grid, Typography } from "@mui/material";
import { React, useRef } from "react";
import Logo from "../../../Assets/LandingPage/navbar/logo.png";
import emailjs from "@emailjs/browser";
function Footer() {
	const form = useRef();

	const sendEmail = (e) => {
		e.preventDefault();

		emailjs
			.sendForm(
				"service_gn1e7yt",
				"template_3snmb8p",
				form.current,
				"Ls_B93RiL5Kx7iBzD"
			)
			.then(
				(result) => {
					console.log(result.text);
					form.current.reset();
				},
				(error) => {
					console.log(error.text);
				}
			);
	};

	return (
		<section id='footer'>
			<Box
				sx={{
					backgroundColor: "hsl(0deg 0% 9.02%)",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					"@media (min-width: 1441px)": {
						minHeight: "auto",
					},
					"@media (max-width: 830px)": {
						minHeight: "auto",
					},
				}}
			>
				<Box sx={{ maxWidth: "1440px", width: "100%" }}>
					<Box
						sx={{
							marginLeft: { sm: "5%", xs: "0%" },
							marginTop: "4%",
							marginRight: { sm: "5%", xs: "0%" },
							marginBottom: "4%",
						}}
					>
						<Grid container spacing={3}>
							<Grid item sm={3} xs={12}>
								<img src={Logo} style={{ width: "70%" }} alt='logo' />
								<Box sx={{ marginLeft: "19px" }}>
									<Typography
										sx={{ color: "white", fontWeight: 600, marginRight: "8px" }}
									>
										We're the IT Robin Hoods, stealing technical headaches and
										giving back digital peace of mind
									</Typography>
								</Box>
							</Grid>
							<Grid item xs={12} sm={3}>
								<Box>
									<Typography
										sx={{
											color: "white",
											fontWeight: 600,
											marginRight: "8px",
											marginLeft: { sm: "0%", xs: "5%" },
										}}
									>
										Services
									</Typography>
									<Box>
										<Typography
											sx={{
												color: "white",
												fontWeight: 600,
												marginRight: "8px",
												marginTop: { sm: "6%", xs: "2%" },
												marginLeft: { sm: "0%", xs: "5%" },
											}}
										>
											UI/UX Design
										</Typography>
										<Typography
											sx={{
												color: "white",
												fontWeight: 600,
												marginRight: "8px",
												marginTop: { sm: "5%", xs: "2%" },
												marginLeft: { sm: "0%", xs: "5%" },
											}}
										>
											Web Application Development
										</Typography>
										<Typography
											sx={{
												color: "white",
												fontWeight: 600,
												marginRight: "8px",
												marginTop: { sm: "5%", xs: "2%" },
												marginLeft: { sm: "0%", xs: "5%" },
											}}
										>
											Business Solutions
										</Typography>
										<Typography
											sx={{
												color: "white",
												fontWeight: 600,
												marginRight: "8px",
												marginTop: { sm: "5%", xs: "2%" },
												marginLeft: { sm: "0%", xs: "5%" },
											}}
										>
											Customized Iot Solutions
										</Typography>
									</Box>
								</Box>
							</Grid>
							<Grid item xs={12} sm={3}>
								<Typography
									sx={{
										color: "white",
										fontWeight: 600,
										marginRight: "8px",
										marginLeft: { sm: "0%", xs: "5%" },
									}}
								>
									Contact Us
								</Typography>
								<Box>
									<Typography
										sx={{
											color: "white",
											fontWeight: 600,
											marginRight: "8px",
											marginTop: { sm: "7%", xs: "2%" },
											marginLeft: { sm: "0%", xs: "5%" },
										}}
									>
										+91 7539923413
									</Typography>
									<Typography
										sx={{
											color: "white",
											fontWeight: 600,
											marginRight: "8px",
											marginTop: { sm: "5%", xs: "2%" },
											marginLeft: { sm: "0%", xs: "5%" },
										}}
									>
										sales@elcodamics.com
									</Typography>
									<Typography
										sx={{
											color: "white",
											fontWeight: 600,
											marginRight: "8px",
											marginTop: { sm: "5%", xs: "2%" },
											marginLeft: { sm: "0%", xs: "5%" },
										}}
									>
										3, Ravindranath Tagore Road, Ganapathy, Coimbatore
									</Typography>
								</Box>
							</Grid>
							<Grid item xs={12} sm={3}>
								<Typography
									sx={{
										color: "white",
										fontWeight: 600,
										marginRight: "8px",
										marginLeft: { sm: "0%", xs: "5%" },
									}}
								>
									Subscribe
								</Typography>
								<Box
									sx={{
										marginTop: { sm: "7%", xs: "2%" },
										marginLeft: { sm: "0%", xs: "5%" },
									}}
								>
									<form
										style={{ display: "flex", gap: "10px" }}
										ref={form}
										onSubmit={sendEmail}
									>
										<input
											name='email'
											type='email'
											placeholder='Email'
											style={{
												border: "1px solid white",
												paddingLeft: "10px",
												height: "35px",
												borderRadius: "10px",
												width: "80%",
												backgroundColor: "transparent",
												color: "White",
											}}
										/>
										<button
											type='submit'
											style={{
												borderRadius: "10px",
												backgroundColor: "#D4BA85",
											}}
										>
											Subscribe
										</button>
									</form>
									<Typography
										sx={{
											color: "white",
											fontWeight: 600,
											marginRight: "8px",
											marginTop: "5%",
										}}
									>
										Unlock the secrets. Subscribe to our newsletter for
										exclusive insights and expert tips
									</Typography>
								</Box>
							</Grid>
						</Grid>
						<Box sx={{ marginTop: "5%" }}>
							<hr />
						</Box>
						<Box>
							<Typography
								sx={{
									color: "white",
									fontWeight: 600,
									marginRight: "8px",
									textAlign: "center",
									marginTop: "3%",
								}}
							>
								Copyright © 2024 ElCodamics
							</Typography>
						</Box>
					</Box>
				</Box>
			</Box>
		</section>
	);
}

export default Footer;
