import { useEffect } from "react";
import $ from "jquery";

const Cursor = () => {
	useEffect(() => {
		const handleMouseMove = (e) => {
			$(".ring").css({
				transform: `translateX(calc(${e.clientX}px - 1.25rem)) translateY(calc(${e.clientY}px - 1.25rem))`,
			});
		};

		$(window).on("mousemove", handleMouseMove);

		// Clean up the event listener when the component unmounts
		return () => {
			$(window).off("mousemove", handleMouseMove);
		};
	}, []);

	return (
		<div id='cursor' class='cursor'>
			<div class='ring'>
				<div></div>
			</div>
			<div class='ring'>
				<div></div>
			</div>
		</div>
	);
};
export default Cursor;
