import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import Iot from "../../../Assets/LandingPage/Team/iot.jpg";
import VR from "../../../Assets/LandingPage/Team/VR.jpg";

function Team() {
	return (
		<section id='products'>
			<Box
				sx={{
					backgroundColor: "hsl(0deg 0% 9.02%)",
					minHeight: "100vh",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					"@media (min-width: 1441px)": {
						minHeight: "auto",
					},
					"@media (max-width: 830px)": {
						minHeight: "auto",
					},
				}}
			>
				<Box sx={{ maxWidth: "1440px", width: "100%", height: "100%" }}>
					<Box
						sx={{
							marginLeft: "5%",
							marginRight: "5%",
							height: "100%",
							marginTop: "10%",
							marginBottom: "10%",
						}}
					>
						<Box>
							<Typography
								sx={{
									color: "hsla(0,0%,100%,.7)",
									marginBottom: "2%",
									fontSize: "16px",
									fontFamily: "Poppins, sans-serif",
									fontWeight: 600,
									lineHeight: "1.75em",
									marginTop: "3%",
									textAlign: "justify",
								}}
							>
								Our Products
							</Typography>
							<Typography
								sx={{
									color: "white",
									fontSize: { sm: "50px", xs: "40px" },
									fontFamily: "Poppins, sans-serif",
									fontWeight: 600,
									lineHeight: 1.25,
								}}
							>
								Awesome Pizza and Pitch <br /> ideas
							</Typography>
						</Box>
					</Box>
					<Box
						sx={{
							marginLeft: "5%",
							marginRight: "5%",
							marginTop: "-5%",
							marginBottom: "5%",
						}}
					>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<Grid container spacing={2}>
								<Grid item sm={3} xs={12}>
									<img
										src={Iot}
										style={{
											width: "100%",
											height: "311px",
											objectFit: "cover",
											borderRadius: "3%",
										}}
									/>
								</Grid>
								<Grid item sm={3} xs={12}>
									<img
										src={VR}
										style={{
											width: "100%",
											height: "311px",
											objectFit: "cover",
											borderRadius: "3%",
										}}
									/>
								</Grid>
								<Grid item sm={3} xs={12}>
									<img
										src={Iot}
										style={{
											width: "100%",
											width: "100%",
											height: "311px",
											objectFit: "cover",
											borderRadius: "3%",
										}}
									/>
								</Grid>
								<Grid item sm={3} xs={12}>
									<img
										src={Iot}
										style={{
											width: "100%",
											width: "100%",
											height: "311px",
											objectFit: "cover",
											borderRadius: "3%",
										}}
									/>
								</Grid>
							</Grid>
						</Box>
					</Box>
				</Box>
			</Box>
		</section>
	);
}

export default Team;
