import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import Funfact_Shape_Bg from "../../../Assets/LandingPage/FunFact/funfact_shape_bg.svg";

function Meeting() {
	return (
		<section id='contact'>
			<Box
				sx={{
					backgroundImage: `url('${Funfact_Shape_Bg}')`,
					backgroundRepeat: "no-repeat",
					backgroundPosition: "center",
					backgroundSize: "cover",
					backgroundColor: "#090909",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					"@media (min-width: 1441px)": {
						minHeight: "auto",
					},
					"@media (max-width: 830px)": {
						minHeight: "auto",
					},
				}}
			>
				<Box sx={{ maxWidth: "1440px", width: "100%" }}>
					<Box
						sx={{
							marginTop: { sm: "4%", xs: "15%" },
							marginBottom: { sm: "4%", xs: "15%" },
						}}
					>
						<Box>
							<Typography
								sx={{
									color: "white",
									fontSize: { sm: "50px", xs: "40px" },
									fontFamily: "Poppins, sans-serif",
									fontWeight: 600,
									lineHeight: 1.25,
									textAlign: "center",
								}}
							>
								Let's discuss, make
							</Typography>
							<Typography
								sx={{
									color: "white",
									fontSize: { sm: "50px", xs: "40px" },
									fontFamily: "Poppins, sans-serif",
									fontWeight: 600,
									lineHeight: 1.25,
									textAlign: "center",
								}}
							>
								something cool together
							</Typography>
						</Box>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								marginTop: "7%",
							}}
						>
							<a href='mailto:sales@elcodamics.com'>
								<Typography
									sx={{ color: "white", fontWeight: 600, marginRight: "8px" }}
								>
									Apply For Meeting
								</Typography>
							</a>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								xmlnsXlink='http://www.w3.org/1999/xlink'
								aria-hidden='true'
								role='img'
								className='iconify iconify--bi'
								width='1em'
								height='1em'
								viewBox='0 0 16 16'
							>
								<path
									fill='white'
									fillRule='evenodd'
									d='M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8'
								></path>
							</svg>
						</Box>
					</Box>
				</Box>
			</Box>
		</section>
	);
}

export default Meeting;
