import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import Fortune from "../../../Assets/LandingPage/Clients/fortuneContainers.png";

function Testimonial() {
	return (
		<section id='testimonial'>
			<Box
				sx={{
					backgroundColor: "#0b0b0b",
					minHeight: "100vh",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					"@media (min-width: 1441px)": {
						minHeight: "auto",
					},
					"@media (max-width: 830px)": {
						minHeight: "auto",
					},
				}}
			>
				<Box sx={{ maxWidth: "1440px", width: "100%", height: "100%" }}>
					<Box
						sx={{
							marginLeft: "5%",
							marginRight: "5%",
							height: "100%",
							marginTop: { sm: "5%", xs: "30%" },
							marginBottom: "2%",
						}}
					>
						<Grid container>
							<Grid
								item
								xs={12}
								sm={4}
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								<img
									src={Fortune}
									style={{
										width: "300px",
										height: "300px",
										objectFit: "contain",
										borderRadius: "100%",
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={8}>
								<Box
									sx={{ marginLeft: "5%", marginTop: { sm: "0%", xs: "10%" } }}
								>
									<Typography
										sx={{
											color: "white",
											fontSize: "16px",
											lineHeight: "1.9em",
											display: { sm: "block", xs: "flex" },
											justifyContent: "center",
											fontWeight: 600,
										}}
									>
										EL Codamics transformed our operations with their intuitive
										ERP portal, eliminating chaos. Crystal-clear inventory,
										seamless purchase orders, and no more stockouts. Operational
										costs slashed, time freed up. Their CRM is a sales
										superhero, boosting satisfaction and sales. Trustworthy
										partners, exceeding expectations.
									</Typography>
									<Typography
										sx={{
											color: "white",
											fontSize: "22px",
											lineHeight: "1.9em",
											fontWeight: 600,
											marginTop: "6%",
										}}
									>
										Raja
										<br />
									</Typography>
									<Typography
										sx={{
											color: "grey",
											fontSize: "18px",
											lineHeight: "1.9em",
											fontWeight: 400,
										}}
									>
										- Managing Director, Fortune Containers
										<br />
									</Typography>
								</Box>
							</Grid>
						</Grid>
					</Box>
				</Box>
			</Box>
		</section>
	);
}

export default Testimonial;
