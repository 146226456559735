import "./App.css";
import Funfact from "./Components/LandingPage/HomePage/Funfact";
import HomePage from "./Components/LandingPage/HomePage/HomePage";
import Cursor from "./Components/common/cursor";
import { Box } from "@mui/material";
import Services from "./Components/LandingPage/HomePage/Services";
import Awards from "./Components/LandingPage/HomePage/Awards";
import Team from "./Components/LandingPage/HomePage/Team";
import Testimonial from "./Components/LandingPage/HomePage/Testimonial";
import { Component } from "react";
import { Navbar } from "./Components/common/navbar";
import Clients from "./Components/LandingPage/HomePage/Clients";
import Meeting from "./Components/LandingPage/HomePage/Meeting";
import Footer from "./Components/LandingPage/HomePage/Footer";

function App() {
	return (
		<>
			<Navbar />
			<Box>
				<Cursor />
				<HomePage></HomePage>
				<Funfact></Funfact>
				<Services></Services>
				<Awards></Awards>
				{/* <Team></Team> */}
				<Testimonial></Testimonial>
				<Clients></Clients>
				<Meeting></Meeting>
				<Footer></Footer>
			</Box>
		</>
	);
}

export default App;
